import React, { useEffect, useState } from 'react';
import './App.css';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import LogRocket from 'logrocket';
import { DayPicker } from "react-day-picker";
import 'react-day-picker/dist/style.css';

function App() {
  LogRocket.init('4kahsi/rgf-enquiry-form');
  const [submitted, setSubmitted] = useState(false)

  function handleSubmit(values: Values,
    {setSubmitting}: FormikHelpers<Values>) {
    console.log('submitted')
    // Correct for British Summer Time bug
    from?.setHours(12);

    const valuesWithDate = {...values, from: from?.toISOString().slice(0,10)}

    const myHeaders = new Headers();
    myHeaders.append("Access-control-allow-origin", "*");

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(valuesWithDate)
    };

    fetch("https://rgf-bookings.vercel.app/api/enquiry", requestOptions)
    // fetch("http://localhost:3000/api/enquiry", requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result)
        setSubmitting(false)
        setSubmitted(true)
      })
      .catch(error => {
        setSubmitting(false)
        console.log('error', error)
      });
  }

  type TInput = {
    label: string,
    name: string,
    placeholder?: string,
    type: string,
    required?: boolean,
    children?: any,
    as?: any,
    rows?: number,
    component?: string,
    autocomplete?: string
  }
  const FormInput = ({label, name, placeholder, type, required = false, children, as, rows, component, autocomplete}: TInput) => (

    <div className="flex-1 flex justify-between capitalize pb-3 text-lg">
      <label className="pr-3" htmlFor="lastName">{required ? `${label}*` : label}:</label>
      <Field id={`${name}Field`} className="border bg-white rounded-md px-2" rows={rows} component={component} required={required}
             type={type} name={name} autocomplete={autocomplete} placeholder={placeholder} as={as}>
        {children}
      </Field>
    </div>
  )

  interface Values {
    firstName: string;
    lastName: string;
    email: string;
    pitchType: string;
    from: string;
  }

  const pitchTypes: string[] = [
    'Motorhome',
    'Campervan',
    'Caravan',
    'Small Caravan',
    'Tent',
    'Yurt',
    'Other'
  ];
  const [from, setFrom] = useState<Date>()
  const [blockedDates, setBlockedDates] = useState<string[]>([])
  const [blockedDatesLoading, setBlockedDatesLoading] = useState<boolean>(true)

  useEffect(() => {
    (async () => {
      const myHeaders = new Headers();
      myHeaders.append("Access-Control-Allow-Origin", "https://book.roughgroundsfarm.co.uk");
      // myHeaders.append("Access-Control-Allow-Origin", "*");


      const requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      fetch("https://rgf-bookings.vercel.app/api/blockedDates", requestOptions)
        // fetch("http://localhost:3000/api/blockedDates", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setBlockedDates(result)
          setBlockedDatesLoading(false)
        })
        .catch(error => {
          // setSubmitting(false)
          console.log('error', error)
        });
    })()
  }, [])

  const blockedMatcher = (day: Date) => {
    return blockedDates.includes(day.toISOString().slice(0,10))
  }

  const LoadingSpinner = ({absolute = false, colour = '#001b3b'}) => {
    return <svg className={`animate-spin z-50${absolute ? ' absolute' : ''}`} width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
      <path d="M23 4V10H17" stroke={colour} strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round"/>
      <path d="M1 20V14H7" stroke={colour} strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round"/>
      <path
        d="M3.51 9C4.01717 7.56678 4.87913 6.2854 6.01547 5.27542C7.1518 4.26543 8.52547 3.55976 10.0083 3.22426C11.4911 2.88875 13.0348 2.93434 14.4952 3.35677C15.9556 3.77921 17.2853 4.56471 18.36 5.64L23 10M1 14L5.64 18.36C6.71475 19.4353 8.04437 20.2208 9.50481 20.6432C10.9652 21.0657 12.5089 21.1112 13.9917 20.7757C15.4745 20.4402 16.8482 19.7346 17.9845 18.7246C19.1209 17.7146 19.9828 16.4332 20.49 15"
        stroke={colour} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>;
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="form-container py-4">
          {submitted && <div className="flex justify-center items-center">
            <div className="max-w-64 md:max-w-96 p-6 border rounded-md text-center">
              <strong className="block text-xl">Booking request sent!</strong> <br/>
              We'll be in touch as soon as we can with either a <strong>Payment Link</strong> or to confirm or chat further via email.
            </div>
          </div>}
          {!submitted &&
            <>
              <h1 className="pb-4 text-2xl">Booking Enquiry</h1>
              <p className="max-w-sm pb-4 text-base">Fill in the form below and we'll double check our current
                availability
                -
                all being well we'll send you a Payment Link (via Email or Text message)
                to secure and confirm your booking.</p>
              <p className="max-w-sm pb-4 text-base">N.B. refunds or rescheduling require at least 48 hours notice,
                and bookings for bank holiday weekends require 14 days notice, just so that we have at least a chance
                to re-fill the pitch!</p>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  from: '',
                  pitchType: 'Motorhome',
                }}
                onSubmit={handleSubmit}
              >
                {(formik) => <Form className="flex flex-col text-left">
                  <FormInput label="First Name" name="firstName" autocomplete="given-name" type="text" placeholder="e.g. Jane" required={true}/>
                  <FormInput label="Last Name" name="lastName" autocomplete="family-name" type="text" placeholder="e.g. Smith" required={true}/>
                  <FormInput label="Email" name="email" autocomplete="email" type="email" placeholder="e.g. jane.smith@gmail.com" required={true}/>
                  <div className="flex-1 flex justify-between capitalize pb-3 text-lg">
                    <label className="mt-3 pr-3 text-lg" htmlFor="lastName">Arrival Date*:</label>
                    <div className="mt-2 relative">
                    {blockedDatesLoading && <div className="flex absolute inset-0 bg-gray-200 bg-opacity-75 justify-center items-center z-30">
                      <LoadingSpinner/>
                    </div>}
                      <DayPicker
                        mode="single"
                        className=""
                        selected={from}
                        disabled={blockedMatcher}
                        onSelect={setFrom}
                      />
                    </div>
                  </div>
                  <FormInput label="No. Nights" name="noNights" type="number" placeholder="" required={true}/>
                  <FormInput label="Phone" name="phone" type="phone" autocomplete="phone" placeholder="###" required={true}/>
                  <FormInput label="Pitch type" name="pitchType" type="select" as="select" placeholder=""
                             required={true}>
                    {pitchTypes.map(type => <option>{type}</option>)}
                  </FormInput>
                  <FormInput label="Vehicle Registration" name="vehicleReg" type="text" placeholder="e.g. AB12CDE" />
                  <FormInput label="Hook Up" name="hookUp" type="checkbox" required={false}/>
                  <FormInput label="Extra Info" name="notes" type="textarea" rows={3} component="textarea"
                             placeholder="e.g. 1 well-behaved border collie"/>
                  <button
                    disabled={formik.isSubmitting || !from}
                          className="bg-yellow-400 rounded-md text-xl py-3 mt-2 disabled:bg-gray-300"
                          type="submit">{!from ? 'Please Select an Arrival Date' : (formik.isSubmitting ? 'Sending Enquiry...' : 'Send Enquiry')}</button>
                </Form>}
              </Formik>
            </>}
        </div>
      </header>

    </div>
  );
}

export default App;
